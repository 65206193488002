const QuotationMarks = () => {
  return (
    <svg width="73" height="67" viewBox="0 0 73 67" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M64.7946 0L70.7364 6.16737C67.7183 11.4003 65.0775 16.5398 62.814 21.5858C60.5504 26.8187 59.3243 31.8647 59.1357 36.7239L73 39.8075V67H42.4419V47.3766C42.4419 35.9763 44.7997 26.6318 49.5155 19.3431C54.0426 12.0544 59.1357 5.60669 64.7946 0ZM22.3527 0L28.2946 6.16737C25.2765 11.4003 22.6357 16.5398 20.3721 21.5858C18.1085 26.8187 16.8824 31.8647 16.6938 36.7239L30.5581 39.8075V67H0V47.3766C0 35.9763 2.35788 26.6318 7.07363 19.3431C11.6008 12.0544 16.6938 5.60669 22.3527 0Z"
        fill="#22D7EE"
      />
    </svg>
  );
};

export default QuotationMarks;
