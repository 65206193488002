import Minus from '@/components/Icon/icons/Minus';
import Plus from '@/components/Icon/icons/Plus';

import Dijitally from './icons/Dijitally';
import QuotationMarks from './icons/QuotationMarks';

export enum IconType {
  Dijitally = 'Dijitally',
  QuotationMarks = 'QuotationMarks',
  Plus = 'Plus',
  Minus = 'Minus',
}

interface IconProps {
  type: IconType | undefined;
}

const Icon = ({ type }: IconProps) => {
  switch (type) {
    case IconType.Dijitally:
      return <Dijitally />;
    case IconType.QuotationMarks:
      return <QuotationMarks />;
    case IconType.Plus:
      return <Plus />;
    case IconType.Minus:
      return <Minus />;
    default:
      return null;
  }
};

export default Icon;
