import { BuilderConfig } from '@/types/builder';

import DijiBlogSummingUp, { Props } from './diji-blog-summing-up';

export const DijiBlogSummingUpBuilderConfig = {
  name: 'Article Summing Up',
  inputs: [
    {
      name: 'items',
      type: 'list',
      defaultValue: '',
      subFields: [
        {
          name: 'title',
          type: 'string',
          defaultValue: '',
        },
        {
          name: 'description',
          type: 'string',
          defaultValue: '',
        },
      ],
    },
  ],
};

const builderConfig: BuilderConfig<Props> = {
  component: DijiBlogSummingUp,
  config: DijiBlogSummingUpBuilderConfig,
};

export default builderConfig;
