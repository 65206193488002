import classNames from 'classnames';
import NextImage from 'next/image';
import React, { FC, PropsWithChildren, useEffect, useRef, useState } from 'react';
import { useWindowSize } from 'usehooks-ts';

import DijiBlogTextWrap, {
  DijiBlogTextWrapProps,
} from '@/components/diji-blog-text-wrap/diji-blog-text-wrap';

import styles from './diji-blog-image-block.module.scss';

export enum IMAGE_POSITION {
  Left = 'Left',
  Right = 'Right',
}

export interface DijiBlogImageBlockProps {
  image?: {
    source?: string;
    overflowValue?: number;
    fluid?: boolean;
    position?: IMAGE_POSITION;
  };
  text?: DijiBlogTextWrapProps & {
    paddingTop?: string;
    paddingBottom?: string;
  };
  topColor?: string;
  bottomColor?: string;
}

const DijiBlogImageBlock: FC<PropsWithChildren<DijiBlogImageBlockProps>> = ({
  text,
  image,
  bottomColor,
  children,
  topColor,
}) => {
  const { width } = useWindowSize();
  const textRef = useRef<HTMLDivElement | null>(null);
  const imageRef = useRef<HTMLDivElement | null>(null);
  const [imageHeight, setImageHeight] = useState<number>(0);
  const [imageRatio, setImageRatio] = useState<number>(0);

  const isOverflow = image?.overflowValue && image.overflowValue < 0;

  const isMobileFluid = width < 992;
  const isFluid = image?.fluid || isMobileFluid;

  const getTextHeight = () => textRef.current?.clientHeight || 0;

  useEffect(() => {
    if (image?.source) {
      const imageEl = new Image();
      imageEl.onload = () => {
        setImageRatio(imageEl.width / imageEl.height);
      };
      imageEl.src = image.source;
    }
  }, []);

  useEffect(() => {
    if (textRef.current) {
      if (!isFluid) {
        setImageHeight(getTextHeight() - (isOverflow ? image?.overflowValue || 0 : 0));
      }
    }
  }, [
    width,
    textRef.current,
    image?.overflowValue,
    isFluid,
    text?.paddingBottom,
    text?.paddingTop,
  ]);

  const getImageHeight = () => {
    if (imageRatio && imageRef.current?.clientWidth)
      return (imageRef.current?.clientWidth || 0) * (1 / imageRatio) * 0.5;
    return 0;
  };

  return (
    <div
      className={classNames(styles.fullWidth, styles.root, {
        [styles.fluid]: isFluid,
        [styles.noOverflow]: !isOverflow,
        [styles.left]: IMAGE_POSITION.Left === image?.position,
        [styles.right]: IMAGE_POSITION.Right === image?.position,
      })}
      style={{ backgroundColor: topColor }}
    >
      <div
        style={{
          paddingTop: !isFluid ? -(image?.overflowValue || 0) : 0,
          backgroundColor: bottomColor,
        }}
        className={classNames(styles.container, styles.fullWidth)}
      >
        <div
          style={{
            backgroundColor: topColor,
            height: isMobileFluid ? getImageHeight() : -(image?.overflowValue || 0),
          }}
          className={classNames(styles.fullWidth, styles.background)}
        />
        <div
          style={{
            paddingTop: !isOverflow ? image?.overflowValue : undefined,
          }}
          className={styles.contentContainer}
        >
          <div className={styles.textBlock}>
            <div className={styles.textItem}>
              <div
                ref={textRef}
                style={{
                  paddingTop: !isMobileFluid ? text?.paddingTop : undefined,
                  paddingBottom: !isMobileFluid ? text?.paddingBottom : undefined,
                }}
              >
                <DijiBlogTextWrap text={text?.text} dark={text?.dark} noPadding />
              </div>
            </div>
            <div className={styles.imageItem}>
              <div
                ref={imageRef}
                style={{
                  paddingTop: isFluid ? `${(1 / imageRatio) * 100}%` : undefined,
                  height: !isFluid ? imageHeight : undefined,
                  // backgroundImage: `url(${image?.source})`,
                }}
                className={styles.image}
              >
                {image?.source ? (
                  <NextImage src={image?.source} alt="" layout="fill" objectFit="cover" />
                ) : null}
              </div>
            </div>
          </div>
          <div className={styles.content}>{children}</div>
        </div>
      </div>
    </div>
  );
};

export default DijiBlogImageBlock;
