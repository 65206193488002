import { BuilderComponentState } from '@builder.io/react/dist/types/src/components/builder-component.component';
import Image from 'next/image';
import Link from 'next/link';
import { FC } from 'react';

import { Article } from '@/types/article';

import HomeHeroSkeleton from '../skeleton/home-hero-skeleton/home-hero-skeleton';
import BlogHomeHeroArticleCarousel from './blog-home-hero-article-carousel/blog-home-hero-article-carousel';
import { BlogHomeHeroBuilderConfig } from './diji-blog-blog-home-hero.builder-component';
import styles from './diji-blog-blog-home-hero.module.scss';

export interface SSProps {
  leadArticle?: Article;
  articles?: Article[];
  builderState?: BuilderComponentState;
}

export interface BuilderProps {}

export interface Props extends SSProps, BuilderProps {}

const BlogHomeHero: FC<Props> = (props) => {
  const { builderState } = props;
  const config = builderState?.state[BlogHomeHeroBuilderConfig.name];
  const leadArticle = config?.leadArticle;
  const articles = config?.articles || [];

  if (!leadArticle) return <HomeHeroSkeleton />;

  const leadImage = leadArticle?.leadPortraitImage || leadArticle?.leadImage;

  return (
    <div className={styles.container}>
      <div className={styles.background}>
        {leadImage ? (
          <Image
            className={styles.background}
            src={leadImage}
            alt=""
            layout="fill"
            objectFit="cover"
            priority
          />
        ) : null}
      </div>
      <div className={styles.content}>
        <div className={styles.titleBox}>
          {leadArticle ? (
            <Link href={leadArticle?.url || '/'} passHref>
              <a className={styles.url}>{leadArticle.title}</a>
            </Link>
          ) : null}
          <div className={styles.title}>{leadArticle?.title}</div>
          <div className={styles.arrow}></div>
          <div className={styles.divider}></div>
        </div>
        {articles.length ? <BlogHomeHeroArticleCarousel articles={articles} /> : null}
      </div>
    </div>
  );
};

export default BlogHomeHero;
