import { withChildren } from '@builder.io/react';

import DijiBlogImageBlock, {
  DijiBlogImageBlockProps,
  IMAGE_POSITION,
} from '@/components/diji-blog-image-block/diji-blog-image-block';
import { BuilderConfig } from '@/types/builder';

export const config = {
  name: 'Image Block',
  inputs: [
    {
      name: 'image',
      type: 'object',
      subFields: [
        {
          name: 'source',
          type: 'file',
        },
        {
          name: 'overflowValue',
          type: 'number',
        },
        {
          name: 'fluid',
          type: 'boolean',
        },
        {
          name: 'position',
          type: 'string',
          enum: Object.values(IMAGE_POSITION),
        },
      ],
    },
    {
      name: 'text',
      type: 'object',
      subFields: [
        {
          name: 'paddingTop',
          type: 'string',
        },
        {
          name: 'paddingBottom',
          type: 'string',
        },
        {
          name: 'dark',
          type: 'boolean',
        },
        {
          name: 'text',
          type: 'richText',
        },
      ],
    },
    {
      name: 'topColor',
      type: 'color',
    },
    {
      name: 'bottomColor',
      type: 'color',
    },
  ],
};

const builderConfig: BuilderConfig<DijiBlogImageBlockProps> = {
  component: withChildren(DijiBlogImageBlock),
  config,
};

export default builderConfig;
