import React from 'react';

import { useWindowSize } from '@/hooks/useWindowSize';

import TextSkeleton from '../text-skeleton/text-skeleton';
import styles from './home-hero-skeleton.module.scss';

const HomeHeroSkeleton = () => {
  const { width = 0 } = useWindowSize();

  return (
    <div className={styles.heroContainer}>
      <div className={styles.pageContainer}>
        <div className={styles.block}>
          <TextSkeleton size={['80%', width > 992 ? 120 : 64]} />
          <TextSkeleton size={['100%', width > 992 ? 120 : 64]} />

          <div className={styles.arrow}>
            <div className={styles.lineSkeleton} />
          </div>
          <div className={styles.divider}>
            <div className={styles.lineSkeleton} />
          </div>
        </div>
        <div className={styles.slider}>
          {[1, 2, 3].map((i) => (
            <div className={styles.item} key={i}>
              <div className={styles.info}>
                <TextSkeleton size={[79, 16]} />
                &nbsp;&nbsp;&nbsp;<span className={styles.dot}>&#8226;</span>&nbsp;&nbsp;&nbsp;
                <TextSkeleton size={[100, 16]} />
              </div>
              <div>
                <TextSkeleton size={['100%', 32]} />
                <br />
                <TextSkeleton size={['75%', 32]} />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default HomeHeroSkeleton;
