import { BuilderConfig } from '@/types/builder';

import BlogHomeHero, { Props } from './diji-blog-blog-hero';

export const BlogHomeHeroBuilderConfig = {
  name: 'Blog Home Hero',
  inputs: [],
};

const builderConfig: BuilderConfig<Props> = {
  component: BlogHomeHero,
  config: BlogHomeHeroBuilderConfig,
};

export default builderConfig;
