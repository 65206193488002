import { Builder } from '@builder.io/react';
import { BuilderComponentState } from '@builder.io/react/dist/types/src/components/builder-component.component';
import classNames from 'classnames';
import Image from 'next/image';
import Link from 'next/link';
import { FC, useEffect, useState } from 'react';

import { formatDate } from '@/helpers/DateHelper';
import { Article } from '@/types/article';
import { BUILDER_MODEL, BuilderReference } from '@/types/builder.d';
import { getHighlightArticle } from '@/utils/Article';

import { DijiBlogHighlightArticleConfig } from './diji-blog-highlight-article.builder-component';
import styles from './diji-blog-highlight-article.module.scss';

export interface SSProps {
  fixedArticle: Article | null;
  builderState?: BuilderComponentState;
}

export interface BuilderProps {
  article?: BuilderReference<BUILDER_MODEL.ARTICLE>;
  topColor?: string;
  bottomColor?: string;
  arrowText?: string;
}

export interface Props extends SSProps, BuilderProps {}

const DijiBlogHighlightArticle: FC<Props> = (props) => {
  const {
    article: builderArticle,
    topColor,
    bottomColor,
    arrowText = 'Recommended',
    builderState,
  } = props;

  const [article, setArticle] = useState<Article | null>(
    builderState?.state?.[DijiBlogHighlightArticleConfig.name]?.fixedArticle || null
  );

  const getDataClientSide = async (): Promise<void> => {
    const article = await getHighlightArticle(builderArticle);
    setArticle(article);
  };

  useEffect(() => {
    if (builderArticle && Builder.isEditing) {
      getDataClientSide();
    }
  }, [builderArticle]);

  if (!article) return null;

  const leadImage = article.leadPortraitImage || article.leadImage;

  return (
    <div className={styles.container}>
      <div
        className={classNames(styles.background, styles.backgroundTop)}
        style={{ backgroundColor: topColor }}
      />
      <div
        className={classNames(styles.background, styles.backgroundBottom)}
        style={{ backgroundColor: bottomColor || article.backgroundColor }}
      />
      <Link href={article.url || '/'} passHref>
        <a className={styles.box} aria-label={article.url}>
          <div className={styles.left}>
            <div className={styles.imageContainer}>
              {leadImage ? (
                <Image
                  className={styles.image}
                  src={leadImage}
                  alt=""
                  layout="fill"
                  objectFit="cover"
                  priority
                />
              ) : null}
            </div>
          </div>
          <div className={styles.right}>
            <div className={styles.textCaption}>
              <span>{arrowText}</span>
              <span className={styles.arrow} />
            </div>
            <div className={styles.info}>
              <span className={styles.category}>{article.categories[0]?.name}</span>
              &nbsp;&nbsp;&nbsp;<span className={styles.dot}>&#8226;</span>&nbsp;&nbsp;&nbsp;
              <span className={styles.date}>
                {article.lastUpdated ? formatDate(article.lastUpdated) : ''}
              </span>
            </div>
            <div className={styles.title}>{article.title}</div>
            {article.author ? (
              <div className={styles.author}>
                {/* <div
                className={styles.avatar}
                style={{ backgroundImage: `url(${article.author.leadImage})` }}
              > */}
                <div className={styles.avatarContainer}>
                  {article.author.leadImage ? (
                    <Image
                      className={styles.avatar}
                      src={article.author.leadImage}
                      alt=""
                      layout="fill"
                      priority
                    />
                  ) : null}
                </div>
                {/* </div> */}
                <div>
                  <div className={styles.name}>{article.author.name}</div>
                  <div className={styles.jobTitle}>{article.author.jobTitle}</div>
                </div>
              </div>
            ) : null}
          </div>
        </a>
      </Link>
    </div>
  );
};

export default DijiBlogHighlightArticle;
