import React, { FC } from 'react';

import { ArticleCardConfig } from '@/components/article-card/article-card';
import TextSkeleton from '@/components/skeleton/text-skeleton/text-skeleton';

import styles from './article-card-skeleton.module.scss';

interface ArticleCardSkeletonProps extends ArticleCardConfig {}

const ArticleCardSkeleton: FC<ArticleCardSkeletonProps> = () => {
  return <TextSkeleton className={styles.skeleton} key={Math.random()} size={['100%', 500]} />;
};

export default ArticleCardSkeleton;
