import classNames from 'classnames';
import { useRouter } from 'next/router';
import { FC, ReactNode } from 'react';

import Spinner from '@/components/spinner/spinner';

import styles from './button.module.scss';

export type HTMLButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement>;

interface ButtonProps extends HTMLButtonProps {
  link?: string;
  children: ReactNode;
  loading?: boolean;
}

const Button: FC<ButtonProps> = (props) => {
  const router = useRouter();
  const { children, link, className, loading, onClick, type, ...buttonProps } = props;

  return (
    <button
      {...buttonProps}
      className={classNames(styles.btn, className)}
      type={type || 'button'}
      onClick={(e) => {
        if (link) {
          router.push(link);
        } else onClick?.(e);
      }}
    >
      {loading && <Spinner />}
      <span>{children}</span>
    </button>
  );
};

export default Button;
