import { FC } from 'react';

import Icon, { IconType } from '../Icon';
import styles from './diji-blog-quote.module.scss';

export interface Props {
  quote: string;
  author: string;
  jobTitle: string;
  background?: string;
}

const DijiBlogQuote: FC<Props> = (props) => {
  const { quote, author, jobTitle, background } = props;

  return (
    <div className={styles.container}>
      {background ? (
        <div className={styles.background} style={{ backgroundColor: background }} />
      ) : null}
      <div className={styles.quoteMark}>
        <Icon type={IconType.QuotationMarks} />
      </div>
      <div className={styles.quoteContent}>
        <div className={styles.quote} dangerouslySetInnerHTML={{ __html: quote }} />
        <div className={styles.author}>
          <div className={styles.name}>{author}</div>
          <div className={styles.jobTitle}>{jobTitle}</div>
        </div>
      </div>
    </div>
  );
};

export default DijiBlogQuote;
