import React from 'react';

const Plus = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
      <path d="M20 0V40" stroke="#0F172A" strokeWidth="2" />
      <path d="M40 20L1.3113e-06 20" stroke="#0F172A" strokeWidth="2" />
    </svg>
  );
};

export default Plus;
