import classNames from 'classnames';
import Image from 'next/image';
import React, { FC } from 'react';

import { ArticleAuthor } from '@/types/article-author';

import styles from './diji-blog-article-author.module.scss';

export interface DijiBlogArticleAuthorPropsProps {
  dark?: boolean;
  articleAuthor: ArticleAuthor;
  prefix?: string;
}

const DijiBlogArticleAuthor: FC<DijiBlogArticleAuthorPropsProps> = ({
  dark = false,
  articleAuthor,
  prefix,
}) => {
  return (
    <div
      className={classNames(styles.root, {
        [styles.dark]: dark,
      })}
    >
      <div className={styles.avatar}>
        <div className={styles.zoomedImageContainer}>
          {articleAuthor.leadImage ? (
            <Image
              className={styles.zoomedImage}
              src={articleAuthor.leadImage}
              alt=""
              layout="fill"
              objectFit="cover"
              priority
            />
          ) : null}
        </div>
      </div>
      <div className={styles.info}>
        <div className={styles.name}>{[prefix, articleAuthor.name].filter(Boolean).join(' ')}</div>
        <div className={styles.jobTitle}>{articleAuthor.jobTitle}</div>
      </div>
    </div>
  );
};

export default DijiBlogArticleAuthor;
