import { BuilderConfig } from '@/types/builder';

import DijiBlogTextCaption, { Props } from './diji-blog-text-caption';

export const DijiBlogTextCaptionBuilderConfig = {
  name: 'Text Caption',
  inputs: [
    {
      name: 'text',
      type: 'richText',
      defaultValue: '',
    },
  ],
};

const builderConfig: BuilderConfig<Props> = {
  component: DijiBlogTextCaption,
  config: DijiBlogTextCaptionBuilderConfig,
};

export default builderConfig;
