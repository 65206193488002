import React from 'react';

const Minus = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="2" viewBox="0 0 40 2" fill="none">
      <path d="M40 1L1.3113e-06 0.999998" stroke="#CBD5E1" strokeWidth="2" />
    </svg>
  );
};

export default Minus;
