import { useEffect, useState } from 'react';

export interface Size {
  width: number | undefined;
  height: number | undefined;
}

export function useWindowSize(): Size {
  const [windowSize, setWindowSize] = useState<Size>({
    width: undefined,
    height: undefined,
  });
  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width:
          document?.documentElement?.clientWidth ||
          document?.body?.clientWidth ||
          window?.innerWidth,

        height:
          document?.documentElement?.clientHeight ||
          document?.body?.clientHeight ||
          window?.innerHeight,
      });
    }
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  return windowSize;
}
