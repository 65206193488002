import classNames from 'classnames';
import Image from 'next/image';
import Link from 'next/link';
import React, { FC } from 'react';

import DijiBlogArticleAuthor from '@/components/diji-blog-article-author/diji-blog-article-author';
import { formatDate } from '@/helpers/DateHelper';
import { Article } from '@/types/article';

import styles from './article-card.module.scss';

export interface ArticleCardConfig {
  dark?: boolean;
  large?: boolean;
  imageFullBleed?: boolean;
}

export interface ArticleCardProps extends ArticleCardConfig {
  article: Article;
}

const ArticleCard: FC<ArticleCardProps> = ({
  dark = false,
  large = false,
  imageFullBleed = false,
  article,
}) => {
  const image = article.leadImage || article.leadPortraitImage;

  return (
    <div
      className={classNames(styles.root, {
        [styles.dark]: dark,
        [styles.large]: large,
        [styles.imageFullBleed]: imageFullBleed,
      })}
    >
      <Link href={article.url || '/'}>
        <a className={styles.link} aria-label={article.url}></a>
      </Link>
      <div className={styles.zoomedImageContainer}>
        {image ? (
          <Image
            className={styles.zoomedImage}
            src={image}
            alt=""
            layout="fill"
            objectFit="cover"
            priority
          />
        ) : null}
      </div>
      <div className={styles.main}>
        <div className={styles.content}>
          <div className={styles.information}>
            <div className={styles.category}>{article.categories?.[0]?.name}</div>
            <div className={styles.dotDivider}>•</div>
            <div className={styles.date}>{formatDate(article.builderModel.createdDate)}</div>
          </div>
          <div className={styles.title}>{article.title}</div>
          <div className={styles.description}>{article.description}</div>
        </div>
        {article.author && (
          <DijiBlogArticleAuthor prefix="By" articleAuthor={article.author} dark={dark} />
        )}
      </div>
    </div>
  );
};

export default ArticleCard;
