import dynamic from 'next/dynamic';

import { BuilderProps } from '@/components/diji-blog-featured-articles/diji-blog-featured-articles';
import { BuilderConfig } from '@/types/builder';

const DijiBlogFeaturedArticles = dynamic(
  () => import('@/components/diji-blog-featured-articles/diji-blog-featured-articles')
);

export const DijiBlogFeaturedArticlesConfig = {
  name: 'Featured Articles',
  inputs: [
    {
      name: 'backgroundColor',
      type: 'color',
    },
    {
      name: 'articles',
      type: 'list',
      copyOnAdd: false,
      subFields: [
        {
          name: 'article',
          type: 'reference',
          model: 'article',
        },
      ],
    },
  ],
};

const builderConfig: BuilderConfig<BuilderProps> = {
  component: DijiBlogFeaturedArticles,
  config: DijiBlogFeaturedArticlesConfig,
};

export default builderConfig;
