import dynamic from 'next/dynamic';

import { DijiBlogHoldingSiteProps } from '@/components/diji-blog-holding-site/diji-blog-holding-site';
import { BuilderConfig } from '@/types/builder';

const DijiBlogHoldingSite = dynamic(
  () => import('@/components/diji-blog-holding-site/diji-blog-holding-site')
);

export const DijiBlogHoldingSiteConfig = {
  name: 'Holding Site',
  inputs: [
    {
      name: 'dribbbleShots',
      type: 'list',
      copyOnAdd: false,
      subFields: [
        { name: 'name', type: 'string' },
        {
          name: 'image',
          type: 'file',
        },
        {
          name: 'url',
          type: 'url',
        },
      ],
    },
    {
      name: 'bringingIdeas',
      type: 'list',
      copyOnAdd: false,
      subFields: [
        {
          name: 'word',
          type: 'string',
        },
      ],
    },
  ],
};

const builderConfig: BuilderConfig<DijiBlogHoldingSiteProps> = {
  component: DijiBlogHoldingSite,
  config: DijiBlogHoldingSiteConfig,
};

export default builderConfig;
