import Link from 'next/link';
import { FC } from 'react';

import { formatDate } from '@/helpers/DateHelper';
import { Article } from '@/types/article';

import styles from './article-carousel-item.module.scss';

interface Props {
  article: Article;
}

const ArticleCarouselItem: FC<Props> = (props) => {
  const { article } = props;

  return (
    <div className={styles.container}>
      <Link href={article.url || '/'} passHref>
        <a className={styles.url} aria-label={article.url}></a>
      </Link>

      <div className={styles.info}>
        <span className={styles.category}>{article.categories[0]?.name}</span>
        &nbsp;&nbsp;&nbsp;<span className={styles.dot}>&#8226;</span>&nbsp;&nbsp;&nbsp;
        <span className={styles.date}>
          {article.lastUpdated ? formatDate(article.lastUpdated) : ''}
        </span>
      </div>
      <div className={styles.title}>{article.title}</div>
    </div>
  );
};

export default ArticleCarouselItem;
