import classNames from 'classnames';
import React, { forwardRef, ForwardRefRenderFunction } from 'react';

import styles from './diji-blog-text-wrap.module.scss';

export interface DijiBlogTextWrapProps {
  text?: string;
  dark?: boolean;
  noPadding?: boolean;
}

const DijiBlogTextWrap: ForwardRefRenderFunction<HTMLDivElement, DijiBlogTextWrapProps> = (
  { text, dark, noPadding },
  ref
) => {
  return (
    <div
      ref={ref}
      className={classNames(styles.root, { [styles.dark]: dark, [styles.noPadding]: noPadding })}
    >
      {text && (
        <div
          className={styles.caption}
          dangerouslySetInnerHTML={{
            __html: text,
          }}
        />
      )}
    </div>
  );
};

export default forwardRef(DijiBlogTextWrap);
