import DijiBlogTextWrap, {
  DijiBlogTextWrapProps,
} from '@/components/diji-blog-text-wrap/diji-blog-text-wrap';
import { BuilderConfig } from '@/types/builder';

export const config = {
  name: 'Text Wrap',
  inputs: [
    {
      name: 'text',
      type: 'richText',
    },
    {
      name: 'dark',
      type: 'boolean',
    },
    {
      name: 'noPadding',
      type: 'boolean',
    },
  ],
};

const builderConfig: BuilderConfig<DijiBlogTextWrapProps> = {
  component: DijiBlogTextWrap,
  config,
};

export default builderConfig;
