import { FC } from 'react';

import styles from './diji-blog-text-caption.module.scss';

export interface Props {
  text: string;
}

const DijiBlogTextCaption: FC<Props> = (props) => {
  const { text } = props;

  return (
    <div className={styles.container}>
      <div className={styles.textCaption} dangerouslySetInnerHTML={{ __html: text }} />
    </div>
  );
};

export default DijiBlogTextCaption;
