import { BuilderConfig } from '@/types/builder';

import DijiBlogQuote, { Props } from './diji-blog-quote';

export const DijiBlogQuoteBuilderConfig = {
  name: 'Quote',
  inputs: [
    {
      name: 'quote',
      type: 'richText',
      defaultValue: '',
    },
    {
      name: 'author',
      type: 'string',
      defaultValue: '',
    },
    {
      name: 'jobTitle',
      type: 'string',
      defaultValue: '',
    },
    {
      name: 'background',
      type: 'color',
      defaultValue: '',
    },
  ],
};

const builderConfig: BuilderConfig<Props> = {
  component: DijiBlogQuote,
  config: DijiBlogQuoteBuilderConfig,
};

export default builderConfig;
