import { FC } from 'react';

import Button from '../button';

export interface Props {
  text: string;
  link: string;
}

const DijiBlogButton: FC<Props> = (props) => {
  const { text, link } = props;

  return <Button link={link}>{text}</Button>;
};

export default DijiBlogButton;
