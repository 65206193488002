import classNames from 'classnames';
import React, { FC } from 'react';

import { ArticleCardConfig } from '@/components/article-card/article-card';
import { defaultTemplates, tabletTemplate } from '@/components/article-grid/article-grid';
import ArticleCardSkeleton from '@/components/skeleton/article-card-skeleton/article-card-skeleton';
import TextSkeleton from '@/components/skeleton/text-skeleton/text-skeleton';
import { useWindowSize } from '@/hooks/useWindowSize';

import styles from './article-grid-skeleton.module.scss';

export interface ArticleGridSkeletonProps {
  templates: ArticleCardConfig[][];
  length: number;
}

const ArticleGridSkeleton: FC<ArticleGridSkeletonProps> = ({ templates, length }) => {
  const windowSize = useWindowSize();
  const handleCards = () => {
    const maxTotalRows = length;
    const cards = [];
    let articleIndex = 0;

    const cardTemplate =
      (windowSize.width || 0) > 1200 ? (templates ? templates : defaultTemplates) : tabletTemplate;

    for (let rowNumber = 0; rowNumber < maxTotalRows; rowNumber += 1) {
      const templateIndex = rowNumber % cardTemplate.length;
      const templateRow = cardTemplate[templateIndex];
      if (articleIndex >= maxTotalRows) {
        break;
      }
      for (let colNumber = 0; colNumber < templateRow.length; colNumber++) {
        const cardType = templateRow[colNumber];
        if (articleIndex >= maxTotalRows) {
          break;
        }
        // const article = filteredArticles[articleIndex];
        if (!cardType.large) {
          cards.push(<ArticleCardSkeleton key={Math.random()} />);
        }
        if (cardType.large) {
          cards.push(
            <div className={styles.articleLarge} key={Math.random()}>
              <ArticleCardSkeleton />
            </div>
          );
        }

        articleIndex += 1;
      }
    }

    return cards;
  };

  const renderArticleContent = () => {
    return <div className={styles.articlesContainer}>{handleCards().map((card) => card)}</div>;
  };

  return (
    <div className={styles.articleGrid}>
      {renderArticleContent()}
      <div className={styles.loadMoreContainer}>
        <div className={styles.skeletonButtonContainer}>
          <TextSkeleton
            className={classNames(styles.skeleton, styles.skeletonButton)}
            size={[166, 40]}
          />
        </div>
        {/*<div*/}
        {/*  className={*/}
        {/*    styles.spinnerLoadMore + ' ' + (isLoadingMoreArticles ? styles.loading : styles.loaded)*/}
        {/*  }*/}
        {/*></div>*/}
      </div>
    </div>
  );
};

export default ArticleGridSkeleton;
