import { BuilderConfig } from '@/types/builder';

import DijiBlogArticleTextList, { Props } from './diji-blog-article-text-list';

export const DijiBlogArticleTextListBuilderConfig = {
  name: 'Article Text List',
  inputs: [
    { name: 'hideShareIcons', type: 'boolean' },
    {
      name: 'contents',
      type: 'list',
      subFields: [
        {
          name: 'text',
          type: 'string',
        },
        {
          name: 'type',
          type: 'text',
          enum: ['default', 'list'],
        },
      ],
    },
  ],
};

const builderConfig: BuilderConfig<Props> = {
  component: DijiBlogArticleTextList,
  config: DijiBlogArticleTextListBuilderConfig,
};

export default builderConfig;
