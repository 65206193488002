import { Builder, builder } from '@builder.io/sdk';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore - Suppressing "unable to find module" error because webpack preloader will load all matching files
// eslint-disable-next-line import/no-unresolved
import * as builderComponentConfigs0 from '../components/diji-blog-all-articles/diji-blog-all-articles.builder-component.ts'; import * as builderComponentConfigs1 from '../components/diji-blog-article-text-list/diji-blog-article-text-list.builder-component.ts'; import * as builderComponentConfigs2 from '../components/diji-blog-blog-home-hero/diji-blog-blog-home-hero.builder-component.ts'; import * as builderComponentConfigs3 from '../components/diji-blog-button/diji-blog-button.builder-component.ts'; import * as builderComponentConfigs4 from '../components/diji-blog-featured-articles/diji-blog-featured-articles.builder-component.ts'; import * as builderComponentConfigs5 from '../components/diji-blog-highlight-article/diji-blog-highlight-article.builder-component.ts'; import * as builderComponentConfigs6 from '../components/diji-blog-holding-site/diji-blog-holding-site.builder-component.ts'; import * as builderComponentConfigs7 from '../components/diji-blog-image-block/diji-blog-image-block.builder-component.ts'; import * as builderComponentConfigs8 from '../components/diji-blog-quote/diji-blog-quote.builder-component.ts'; import * as builderComponentConfigs9 from '../components/diji-blog-summing-up/diji-blog-summing-up.builder-component.ts'; import * as builderComponentConfigs10 from '../components/diji-blog-text-caption/diji-blog-text-caption.builder-component.ts'; import * as builderComponentConfigs11 from '../components/diji-blog-text-wrap/diji-blog-text-wrap.builder-component.ts'; let builderComponentConfigs = [builderComponentConfigs0, builderComponentConfigs1, builderComponentConfigs2, builderComponentConfigs3, builderComponentConfigs4, builderComponentConfigs5, builderComponentConfigs6, builderComponentConfigs7, builderComponentConfigs8, builderComponentConfigs9, builderComponentConfigs10, builderComponentConfigs11];
import { BUILDER_API_KEY } from '../shared/env';

builder.init(BUILDER_API_KEY);

export const registerComponents = () => {
  for (const exports of builderComponentConfigs) {
    if (Object.hasOwnProperty.call(exports, 'default')) {
      const { component, config } = exports.default;

      if (component && config) {
        Builder.registerComponent(component, config);
      }
    }
  }
};
