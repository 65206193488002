import { FC } from 'react';
import type { SwiperOptions } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import { Article } from '@/types/article';

import ArticleCarouselItem from './article-carousel-item/article-carousel-item';
import styles from './blog-home-hero-article-carousel.module.scss';

const options: SwiperOptions = {
  slidesPerView: 'auto',
  spaceBetween: 24,
  allowTouchMove: true,
  noSwiping: false,
  freeMode: true,
  breakpoints: {
    992: {
      watchOverflow: true,
    },
  },
};

interface Props {
  articles: Article[];
}

const BlogHomeHeroArticleCarousel: FC<Props> = (props) => {
  const { articles } = props;

  if (!articles?.length) return null;

  return (
    <div className={styles.container}>
      <div className={styles.box}>
        <Swiper {...options}>
          {articles.map((article) => (
            <SwiperSlide key={article.id}>
              <div className={styles.item}>
                <ArticleCarouselItem key={Math.random()} article={article} />
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
};

export default BlogHomeHeroArticleCarousel;
