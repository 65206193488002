const Dijitally = () => {
  return (
    <svg
      width="175"
      height="16"
      viewBox="0 0 175 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M168.171 0H175L164.181 9.19101V15.7303H159.222V9.16854L148.426 0H155.233L161.702 5.34832L168.171 0Z"
        fill="white"
      />
      <path d="M141.513 11.3933H154.564V15.7303H136.555V0H141.513V11.3933Z" fill="white" />
      <path d="M121.255 11.3933H134.305V15.7303H116.296V0H121.255V11.3933Z" fill="white" />
      <path
        d="M108.78 15.7303L108.126 13.236H96.473L95.8193 15.7303H90.8154L94.9853 0H109.636L113.806 15.7303H108.78ZM97.5999 8.85393H106.977L105.804 4.33708H98.772L97.5999 8.85393Z"
        fill="white"
      />
      <path
        d="M91.7235 0V4.33708H81.9637V15.7303H77.0049V4.33708H67.2451V0H91.7235Z"
        fill="white"
      />
      <path d="M60.0254 15.7303V0H64.9842V15.7303H60.0254Z" fill="white" />
      <path
        d="M57.7736 0V8.5618C57.7736 10.9888 57.0899 12.839 55.7225 14.1124C54.355 15.3708 52.4467 16 49.9973 16H40.9814C38.502 16 36.5861 15.3858 35.2337 14.1573C33.8813 12.9139 33.2051 11.0487 33.2051 8.5618H38.1639C38.1639 9.47566 38.3893 10.2097 38.8401 10.764C39.2909 11.3184 40.0046 11.5955 40.9814 11.5955H49.9973C50.9891 11.5955 51.7028 11.3184 52.1386 10.764C52.5894 10.2097 52.8148 9.47566 52.8148 8.5618V0H57.7736Z"
        fill="white"
      />
      <path d="M26.4355 15.7303V0H31.3943V15.7303H26.4355Z" fill="white" />
      <path
        d="M15.9583 0C18.4827 0 20.5564 0.711611 22.1793 2.13483C23.8172 3.55805 24.6361 5.46816 24.6361 7.86517C24.6361 10.2472 23.8172 12.1573 22.1793 13.5955C20.5564 15.0187 18.4827 15.7303 15.9583 15.7303H0V0H15.9583ZM15.9583 11.4157C17.0101 11.4008 17.8742 11.0562 18.5504 10.382C19.2416 9.70787 19.5872 8.86891 19.5872 7.86517C19.5872 6.84644 19.2416 6 18.5504 5.32584C17.8742 4.65169 17.0101 4.31461 15.9583 4.31461H4.93624V11.4157H15.9583Z"
        fill="white"
      />
    </svg>
  );
};

export default Dijitally;
