import { BUILDER_MODEL, BuilderConfig } from '@/types/builder.d';

import DijiBlogHighlightArticle, { Props } from './diji-blog-highlight-article';

export const DijiBlogHighlightArticleConfig = {
  name: 'Highlight Article',
  inputs: [
    {
      name: 'article',
      type: 'reference',
      model: BUILDER_MODEL.ARTICLE,
    },
    {
      name: 'topColor',
      type: 'color',
      defaultValue: '#0F172A',
    },
    {
      name: 'bottomColor',
      type: 'color',
      defaultValue: '#FDB1B8',
    },
  ],
};

const builderConfig: BuilderConfig<Props> = {
  component: DijiBlogHighlightArticle,
  config: DijiBlogHighlightArticleConfig,
};

export default builderConfig;
