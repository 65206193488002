import BuilderSDK from '@builder.io/sdk';
import { ComponentType } from 'react';

export type BuilderConfig<P> = {
  component: ComponentType<P>;
  config: BuilderSDK.Component;
  asyncProps?: (props: P) => Promise<Record<string, unknown>>;
};

export type BuilderReference<BuilderModel> = {
  '@type': '@builder.io/core:Reference';
  id: string;
  model: BuilderModel;
  value?: BuilderContent;
};

export enum BUILDER_MODEL {
  ARTICLE = 'article',
  ARTICLE_AUTHOR = 'article-author',
  PAGE = 'page',
  CATEGORY = 'category',
}
