import { BuilderContent } from '@builder.io/react';

import { ArticleCardConfig } from '@/components/article-card/article-card';
import ArticleModel from '@/models/ArticleModel';
import { Article } from '@/types/article';
import { BUILDER_MODEL, BuilderReference } from '@/types/builder';

export const getHeroArticles = async (): Promise<Article[]> => {
  const builderContent = (await ArticleModel.findAll({
    filters: { offset: 0, limit: 4 },
    includeUnpublished: false,
  })) as BuilderContent<Article>[];
  return ArticleModel.fromListBuilderContent(builderContent);
};

export const getLatestArticle = async (): Promise<Article | null> => {
  const [article] = await ArticleModel.findAll({ filters: { offset: 0, limit: 1 } });
  if (article) return ArticleModel.fromBuilderContent(article);
  return null;
};

export const getHighlightArticle = async (
  article?: BuilderReference<BUILDER_MODEL.ARTICLE>
): Promise<Article | null> => {
  let fixedArticle;
  if (article?.id) {
    [fixedArticle] = await ArticleModel.fromBuilderReference([{ article: article }]);
  } else {
    fixedArticle = await getLatestArticle();
  }
  return fixedArticle;
};

export const getNextArticle = async (article: Article): Promise<Article | null> => {
  if (article.id) {
    const nextArticle = await ArticleModel.findNextById(article.id, article?.createdDate);
    if (nextArticle) return ArticleModel.fromBuilderContent(nextArticle);
  }
  return null;
};

export const getArticle = async (articleId: string): Promise<Article | null> => {
  const article = await ArticleModel.findArticle({ params: { modelId: articleId } });
  if (article) return ArticleModel.fromBuilderContent(article);
  return null;
};

export const getRelatedArticle = async (article: Article) => {
  let categoryFilter = {};
  if (article.categories?.length && article.categories.every((cate) => !!cate.id)) {
    categoryFilter = {
      'data.categories': {
        $elemMatch: {
          'category.id': {
            $in: article.categories.map(({ id }) => id),
          },
        },
      },
    };
  }

  return (
    await ArticleModel.findAll({
      sort: {
        createdDate: -1,
      },
      filters: {
        limit: 3,
        offset: 0,
        extra: {
          id: {
            $ne: article.id,
          },
          ...categoryFilter,
        },
      },
    })
  ).map(ArticleModel.fromBuilderContent);
};

export const getFeaturedArticles = async (
  articles?: {
    article?: BuilderReference<BUILDER_MODEL.ARTICLE>;
  }[]
): Promise<Article[]> => {
  let fixedArticles = [];
  if (articles?.length && articles?.some((article) => article?.article?.id)) {
    fixedArticles = await ArticleModel.fromBuilderReference(articles);
  } else {
    fixedArticles = (
      await ArticleModel.findAll({
        filters: { offset: 4, limit: 3 },
        includeUnpublished: false,
      })
    ).map(ArticleModel.fromBuilderContent);
  }
  return fixedArticles;
};

export const getMoreArticles = async (offset: number, itemsPerPage: number): Promise<Article[]> => {
  return (
    await ArticleModel.findAll({
      filters: { offset: offset, limit: itemsPerPage },
      includeUnpublished: false,
      sort: {
        createdDate: -1,
      },
    })
  ).map(ArticleModel.fromBuilderContent);
};

export const getItemsPerPage = (
  rows: number,
  currentRow: number,
  template: ArticleCardConfig[][]
) =>
  Array(rows)
    .fill(undefined)
    .reduce((result, _, index) => {
      const row = (index + currentRow) % template.length;
      return (result += template[row].length);
    }, 0);

export const getArticleById = async (articleId?: string | string[]) => {
  if (!articleId) return;
  const article = await ArticleModel.findArticle({
    params: { modelId: Array.isArray(articleId) ? articleId[0] : articleId },
    includeRefs: true,
  });
  return article;
};
