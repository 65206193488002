import cx from 'classnames';
import { FC, useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';

import styles from './share-icons.module.scss';
import { getTooltipMessage, getUrlShare, handlePopupCenter } from './share-icons.utils';
import IconFacebook from './social-and-share-icon/facebook-social';
import IconLinkedIn from './social-and-share-icon/linkedIn-social';
import ThreeDot from './social-and-share-icon/three-dot';
import IconTwitter from './social-and-share-icon/twitter-social';

interface ButtonItem {
  type: string;
  urlShare: string;
  compShare: React.ReactNode;
}

const socialAndShares: ButtonItem[] = [
  { type: 'facebook', urlShare: '', compShare: <IconFacebook /> },
  { type: 'twitter', urlShare: '', compShare: <IconTwitter /> },
  { type: 'linkedIn', urlShare: '', compShare: <IconLinkedIn /> },
  { type: 'copy', urlShare: '', compShare: <ThreeDot /> },
];
export interface Props {
  shareUrl?: string;
  vertical?: boolean;
}

const ShareIcons: FC<Props> = (props) => {
  const { shareUrl, vertical } = props;
  const [isCopied, setIsCopied] = useState(false);

  return (
    <div className={cx(styles.container, { [styles.vertical]: vertical })}>
      {(socialAndShares || []).map((item) => {
        const linkShare = getUrlShare(item.type, item.urlShare || shareUrl!);

        if (item.type === 'copy') {
          return (
            <CopyToClipboard
              key={item.type}
              text={linkShare}
              onCopy={() => {
                setIsCopied(true);
                setTimeout(() => {
                  setIsCopied(false);
                }, 3000);
              }}
            >
              <div key={item.type} className={styles.socialAndShareLink}>
                {item.compShare}
                <span className={styles.socialAndShareTooltip}>
                  {isCopied ? 'Copied' : getTooltipMessage(item.type)}
                </span>
              </div>
            </CopyToClipboard>
          );
        }

        return (
          <div
            key={item.type}
            className={styles.socialAndShareLink}
            onClick={() => {
              handlePopupCenter({
                url: linkShare,
                title: getTooltipMessage(item.type),
                popupWidth: 400,
                popupHeight: 300,
              });
            }}
          >
            {item.compShare}
            <span className={styles.socialAndShareTooltip}>{getTooltipMessage(item.type)}</span>
          </div>
        );
      })}
    </div>
  );
};

export default ShareIcons;
