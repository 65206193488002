import { BuilderContent } from '@builder.io/react';

import { BLOG_HOME_PAGE } from '@/shared/constant';
import { Article } from '@/types/article';
import { BUILDER_MODEL, BuilderReference } from '@/types/builder';

import BaseModel, { IFind } from './BaseModel';
import CategoryModel from './CategoryModel';

class ArticleModel extends BaseModel {
  constructor() {
    super({ modelName: 'article' });
  }

  convertBuilderURL2Slug = (url: string) => {
    if (!url) return null;

    return `${BLOG_HOME_PAGE}${url.replace(/^(\/article|\/builder-preview)/, '') ?? ''}`;
  };

  convertSlug2BuilderURL = (slug: string) => {
    if (!slug) return null;

    return `/article${`/${slug}`.replace(BLOG_HOME_PAGE, '')}`;
  };

  fromBuilderContent = (object: any): Article => {
    const oData = object?.data;

    const categories =
      oData?.categories?.map((cate: any) =>
        CategoryModel.fromBuilderContent(cate.category.value)
      ) || [];
    const author = oData?.author?.value?.data;
    const articleSlug = this.convertBuilderURL2Slug(oData?.url) || '';

    return {
      id: object?.id ?? null,
      title: oData?.title ?? '',
      description: oData?.description ?? '',
      summary: oData?.summary ?? '',
      categories: categories,
      author: author ?? null,
      keywords: oData?.keywords ?? '',

      backgroundColor: oData?.backgroundColor ?? null,
      leadImage: oData?.leadImage ?? null,
      leadPortraitImage: oData?.leadPortraitImage ?? null,

      url: articleSlug ?? '',
      priority: oData?.priority ?? 0,

      builderModel: object ?? null,
      seoTitle: oData?.seoTitle ?? oData?.title ?? '',
      seoDescription: oData?.seoDescription ?? oData?.description ?? '',

      lastUpdated: object?.lastUpdated ?? null,
      createdDate: object?.createdDate ?? null,
      firstPublished: object?.firstPublished ?? null,

      readingTime: oData?.readingTime ?? null,
    };
  };

  fromBuilderReference = async (
    articles?: { article?: BuilderReference<BUILDER_MODEL.ARTICLE> }[]
  ): Promise<Article[]> => {
    if (!articles) return [];
    const articleIds = articles.filter((a) => a.article?.id).map((a) => a.article?.id) as string[];
    const updatedArticles = this.fromListBuilderContent(
      (await this.findByIds(articleIds)) as BuilderContent<Article>[]
    );
    return updatedArticles.sort((a, b) => {
      const findIndex = (article: Article): number =>
        articleIds.findIndex((id) => id === article.id);
      return findIndex(a) - findIndex(b);
    });
  };

  fromListBuilderContent = (objects: BuilderContent<Article>[]): Article[] => {
    return objects.map(this.fromBuilderContent);
  };

  getAllArticles = async (includeUnpublished = false, includeDraft = false) => {
    const draft = includeDraft ?? 'draft';
    const articles = await this.builder.getAll(this.modelName, {
      options: { noTargeting: true, includeUnpublished },
      query: {
        published: {
          $ni: ['archived', draft],
        },
      },
    });
    return articles;
  };

  findArticle = async (filter: IFind) => {
    if (filter.params?.url) {
      filter.params.url = this.convertSlug2BuilderURL(filter.params.url) || undefined;
    }

    return this.find(filter);
  };
}

export default new ArticleModel();
