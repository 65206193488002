import DijiBlogAllArticles, {
  BuilderProps,
} from '@/components/diji-blog-all-articles/diji-blog-all-articles';
import { BuilderConfig } from '@/types/builder';

export const DijiBlogAllArticlesConfig = {
  name: 'All Articles',
  inputs: [
    {
      name: 'title',
      type: 'string',
    },
    {
      name: 'offset',
      type: 'number',
      helperText: 'Default is 7',
    },
  ],
};

const builderConfig: BuilderConfig<BuilderProps> = {
  component: DijiBlogAllArticles,
  config: DijiBlogAllArticlesConfig,
};

export default builderConfig;
