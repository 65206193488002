import React from 'react';

const TwitterSocial = () => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M30.6654 4.00022C29.3885 4.90086 27.9748 5.5897 26.4787 6.04022C25.6757 5.11691 24.6085 4.46248 23.4214 4.16546C22.2343 3.86844 20.9847 3.94315 19.8415 4.3795C18.6983 4.81584 17.7166 5.59276 17.0293 6.60518C16.3421 7.6176 15.9823 8.81667 15.9987 10.0402V11.3736C13.6555 11.4343 11.3337 10.9146 9.24004 9.86082C7.14636 8.807 5.34579 7.25174 3.9987 5.33356C3.9987 5.33356 -1.33464 17.3336 10.6654 22.6669C7.9194 24.5308 4.64824 25.4655 1.33203 25.3336C13.332 32.0002 27.9987 25.3336 27.9987 10.0002C27.9975 9.62883 27.9618 9.25835 27.892 8.89356C29.2528 7.55155 30.2131 5.85717 30.6654 4.00022V4.00022Z"
        stroke="#0F172A"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default TwitterSocial;
