import { BuilderConfig } from '@/types/builder.d';

import ArticleCarousel, { Props } from './diji-blog-button';

export const ArticleCarouselBuilderConfig = {
  name: 'Button',
  inputs: [
    {
      name: 'text',
      type: 'text',
    },
    {
      name: 'link',
      type: 'url',
    },
  ],
};

const builderConfig: BuilderConfig<Props> = {
  component: ArticleCarousel,
  config: ArticleCarouselBuilderConfig,
};

export default builderConfig;
